import './App.css'

// RCE CSS
import 'react-chat-elements/dist/main.css';

import React, {Component} from 'react'
import { RXDashboard }  from '@radixnpm/rx-dashboard'

const appLoader = (page) => import(`./pages/${page}`)

import MqttManager from "./managers/MqttManager"

const loaders = {
    "app": appLoader
}

const config = require('./config.json')

class App extends Component {
  
  constructor(props){
    
    super(props)
    this.mqttManager = new MqttManager()
    
  }

  
  componentDidMount = () => {
    
    console.log(`2000 - in dashboard component did mount`)
    
  }
  
  onLogin = async (data) => {
    
    console.log('3001c - done login with data: ', await data)
    this.mqttManager.start(data, config)
  }
  
  onResumeLogin = async (data) => {
    
    console.log('3001c - done login with data: ', await data)
    this.mqttManager.start(data, config)
  }  
  
  render() {
    return <RXDashboard 
      configuration={config} 
      loaders={loaders} 
      className="aside-menu-fixed" 
      events={{
        onLogin: (data)=>this.onLogin(data),
        onResumeLogin: (data)=>this.onResumeLogin(data)
      }}
      managers={{mqtt: this.mqttManager}}
    />
  }
}

export default App
