import React, {Component} from 'react'
var awsIot = require('aws-iot-device-sdk');
import { createStore } from 'redux';

const logdown = require('logdown')
const console = logdown('sosangels-admin-mqtt-manager', { prefixColor: '#1976d2' })

const ManagerStates = {
    UNDEFINED: -1,
    INITIALIZED: 0,
    STARTED: 1,
    CONNECTED: 2,
    DISCONNECTED: 3,
    RECONNECTED: 4
};

const Actions = {
    INIT: 0,
    CHANGE_STATE: 1,
    RECEIVE_MESSAGE: 2,
    SEND_MESSAGE: 3
};

const States = {
    UNDEFINED: -1,
    INITIALIZED: 0,
    CHANGE_STATE: 1,
    RECEIVE_MESSAGE: 2,
    SEND_MESSAGE: 3
};

const Reducer = (state = 0, action) => {
    console.log(`in reducer with: ${state} and action: ${JSON.stringify(action)}`);
    switch (action.type) {        

        case Actions.CHANGE_STATE:
            console.log(`in changestate`)
            return {state: States.CHANGE_STATE, managerState: action.managerState}
        
        case Actions.RECEIVE_MESSAGE:
            console.log(`in receiveMessage`)
            return  {state: States.RECEIVE_MESSAGE, message: action.message}
            
        default:
            return {state: States[action.type]};
            
    }
}

class MqttManager extends Object {
 
 
    constructor(props){
        super(props)
        
        this.currentState = ManagerStates.INITIALIZED
        this.store = createStore(Reducer)
        this.reconnection = false        
    } 
    
    start = (credentials, config) => {
        console.log(`3001f: in start for mqttmanager: `, config.authentication.configuration, credentials)
        console.log(`3001g: identityid: `, credentials.identityId)
        console.log(`3001h: host: `, config.authentication.configuration.mqttEndpoint)

        this.device = awsIot.device({
            host: config.authentication.configuration.mqttEndpoint,
            protocol: "wss",
            clientId: `${credentials.identityId}`,
            autoResubscribe: true,
            accessKeyId: credentials.accessKeyId,
            secretKey: credentials.secretAccessKey,
            sessionToken: credentials.sessionToken,
            will: {
                topic: "topic_2",
                payload: JSON.stringify({ status: "disconnected"})
            }
        })      
        
        this.userid = credentials.identityId
        this.states = States
        
        this.device
        .on('connect', (connack) => {
            console.log(`connect mqtt ${JSON.stringify(connack)}`);
            if(this.reconnection){
                this.currentState = ManagerStates.RECONNECTED
                this.reconnection = false
            }else{
                this.currentState = ManagerStates.CONNECTED
            }
            this.store.dispatch({type: States.CHANGE_STATE, managerState: this.currentState})
            console.log(`after dispatch`)
            
            this.device.subscribe("sosangels/client-status", {qos: 1}, (err, success)=> {

                console.log(`error: ${err}`)
                console.log(`success: ${JSON.stringify(success)}`)
            })
            

        })
        .on('message', (topic, message) => {
            console.log(`received message: ${message}`)
            this.store.dispatch({
            type: Actions.RECEIVE_MESSAGE,
            message: `${message}`
        }); 
        })
        .on('reconnect', () => {
            console.log(`starting reconnect`)
            this.reconnection = true
        })
        .on('error', (err) => {
            console.log(`error`)
            console.log(Object.keys(err));
            console.log(`type:`, err.type)
            console.log(`timeStamp:`, err.timeStamp);
        })
        .on('offline', () => {
            console.log(`offline`)
        })
        .on('close', () => {
            console.log(`close`)
        })
        
        
    }     
    
    publish = (topic, payload) => {
        this.device.publish(topic, payload, {qos: 1})
    }    

    get actions(){
        return Actions
    }

    /*get states(){
        return States
    }*/

    get managerStates(){
        return ManagerStates
    }    
    
}

export default MqttManager
